import * as React from 'react';

const BringLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="40" viewBox="0 0 500 200">
      <path
        fill="#7bc144"
        d="M41.2 95.2C42.6 77 57.9 62.6 76.5 62.6s33.9 14.4 35.3 32.6H177c-1.5-37.8-32.6-68-70.8-68-38.2 0-69.3 30.2-70.8 68h5.8z"
      />
      <path
        fill="#c1c1c1"
        d="M111.8 100.8c-1.4 18.2-16.7 32.6-35.3 32.6S42.6 119 41.2 100.8h-5.7c1.5 37.8 32.6 68 70.8 68 38.2 0 69.3-30.2 70.8-68h-65.3z"
      />
      <path
        fill="#7bc144"
        d="M228.3 99.7c-6.8 0-14.2.8-18.9 5.6V80.7h-14.5v82.5h13v-5c3.8 3.5 9.7 6 18 6 24.9 0 26.6-16.9 26.6-32.1.1-18.9-2.9-32.4-24.2-32.4zm-3.2 51.5c-12.4 0-16-3.5-16-19.1 0-15.6 3.6-19.1 16-19.1 10.7 0 13 4.5 13 19.1 0 14.6-2.3 19.1-13 19.1zm64.7-51.5c-7.3 0-13.1 2.3-17.1 6.7v-5.6h-12.5v62.4h14.5v-27.1c0-4.9-.3-11.8 1.4-16.2 1.5-4.2 5.2-7.4 11.3-7.4 6 0 8.4 1.3 8.4 7H310c0-13.7-6.8-19.8-20.2-19.8zm81.5 0c-8.8 0-14.5 2.8-18 6v-4.9h-12.5v62.4h14.5v-29c0-10.9-1.1-21.6 14.5-21.6 12.5 0 11.4 5.8 11.4 17.8v32.9h14.5v-34c-.2-13.7.8-29.6-24.4-29.6zm76.3 1.1v4.9c-4.9-4.3-12.8-6-19.4-6-21.5 0-24.9 12.9-24.9 31.9 0 15 1.4 31.6 25.1 31.6 6 0 13.4-1.4 17.9-5.5 0 3.2.1 7.7-.3 10.9-.8 5.9-6.8 8.4-15.1 8.4-8.1 0-10.6-2.3-10.6-6.8h-15.7c0 15.9 14.2 19 26.7 19 11.5 0 26.6-3.1 28.9-17.9.5-3.5.5-9.1.5-14.9v-55.7h-13.1v.1zm-17 49.3c-10.7 0-12.9-4-12.9-18.6s2.2-19.1 12.9-19.1c12.4 0 15.7 3.5 15.7 19.1 0 15.7-3.3 18.6-15.7 18.6zm-113.7 13.1h14.5v-62.4h-14.5v62.4zm0-68h14.5V80.7h-14.5v14.5z"
      />
    </svg>
  );
};

export default BringLogo;
