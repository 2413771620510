const sv = {
  error: {
    unknownError: 'Det har uppstått ett fel. Prova igen senare.',
  },
  heading: {
    entrances: 'Area entrances',
    tasks: 'Notifications',
    dashboard: 'Dashboard',
    info: 'Information and support',
    serviceChange: 'Service changes'
  },
  resources: {
    booking: 'Beställning',
    orderManagement: 'Order Management',
    airAndSea: 'Air and Sea',
    calculator: 'Kalkylator',
    tracking: 'Spårning',
    reports: 'Rapporter',
    invoices: 'Fakturor',
    docSearch: 'Dokumentsökning',
    supportCases: 'Kundtjänst',
    useradminExternal: 'Administration',
    customerSearch: 'Customer Search',
    warehousing: 'Warehousing',
    incoming: 'Inkommande försändelser',
    checkout: 'Checkout',
    marketing: 'Marknadsföring',
    addresses: 'Adressfiler (Norge)',
    noAccess: 'Du har inte tillgång till',
    getAccess: 'Du kan ansöka om tillgång och rättigheter via <0>din profil</0>.'
  },
  entrances: {
    noShow: 'Inga ingångar visas, lägg till en genom att klicka',
    showTile: 'Visa ingång',
    move: 'Flytta',
    left: 'till vänster',
    right: 'till höger',
    hide: 'Dölj',
    editEntrances: 'Redigera',
    closeConfiguration: 'Stäng redigering',
    configInfo: 'You can change the order by using drag-n-drop or the buttons on each entrance.',
    hiddenTilesHeading: 'Hidden entrances',
    hiddenTilesInfo: 'If you choose to hide some entrances, they will be listed here. You can always show them again.',
    disabledAccessHeading: 'Disabled access'
  },
  customerService: {
    label: 'Kontakta kundtjänst',
    openingHours: 'Måndag–fredag, ',
    keypad: 'Mybring: tryck ',
    trackingKeypad: 'Spårning: tryck ',
    then: ' sedan ',
    email: 'E-post',
    phone: 'Telefon',
    openCase: 'Skapa ett ärende i',
    guides: 'Mybring- och API-guider',
    mybringInfo: 'Få hjälp med Mybring på våra',
    mybringInfoLinkText: 'rådgivningssidor',
    mybringInfoLink: 'https://www.bring.se/radgivning/mybring',
    apiInfo: 'Läs om våra API:er och hur du integrerar med dem i',
    apiInfoLink: 'Bring API-dokumentationen',
    apiUpdatesHeading: 'Prenumerera på API-uppdateringar',
    apiUpdatesText: 'Prenumerera på <0>API-uppdateringar</0> från Bring.',
    apiUpdatesButton: 'Registrera e-post',
    feedback: 'Feedback',
    feedbackHead: 'Hjälp oss att förbättra Mybring',
    feedbackInfo: 'Har du synpunkter på hur Mybring kan förbättras? Ge feedback direkt till utvecklingsteamen.',
    feedbackForm: 'Öppna formuläret',
  },
  revisedServices: {
    description: 'Några av dina kundnummer har fått tjänster med nya namn och prismodeller.',
    link: 'Information om ändringarna',
  },
  oebsW3: {
    apiLabel: 'Förändringar på API',
    webLabel: 'Förändringar på web',
    intro: 'Från och med den 2 maj 2023 byter vi till ett nytt och förbättrat faktureringssystem för våra Home Delivery-tjänster i Norge och Danmark samt regionala budtjänster i Finland. Nedan finns en översikt över de förändringar du kommer att se på olika platser i Mybring og vår API.',
    api: {
      title: 'Förändringar i Mybrings APIer till följd av nytt faktureringssystem',
      intro:
        'Från och med den 2 maj 2023 byter vi till ett nytt och förbättrat faktureringssystem för våra Home Delivery-tjänster i Norge och Danmark samt regionala budtjänster i Finland. Nedan följer en översikt över de ändringar som gäller för dig som API-kund.',
      customernumbers: 'Dina API-kundenummer.',
      sg: {
        label: 'Shipping Guide API',
        info1:
          'Förfrågningar om utgående Home Delivery-tjänster till/för Norge och Danmark, Home Delivery-returtjänster till/från Norge och Danmark samt regionala budtjänster i Finland ska göras med huvudkundnummer. Du måste själv ändra till ditt nya API-huvudkundnummer för sådana förfrågningar. Ditt API-huvudkundnummer är identiskt med ditt företags huvudkundnummer. ',
        info2:
          'Ditt gamla API-kundnummer kommer fortfarande att fungera under en övergångsperiod. Vi rekommenderar dock att du ',
        link: 'ändrar API-kundnumret som används för förfrågningar om nämnda tjänster',
        text: ' så snart som möjligt för att undvika problem senare.',
      },
      booking: {
        label: 'Booking API',
        info1:
          'Beställning av Home Delivery-tjänster till/för Norge och Danmark som görs via Booking API ska göras med huvudkundnummer. Detta gäller både utgående Home Delivery-tjänster till/för Norge och Danmark och Home Delivery-returtjänster till/från Norge och Danmark.',
        info2:
          'Du måste själv ändra till ditt nya API-huvudkundnummer för sådana beställningar. Ditt API-huvudkundnummer är identiskt med ditt företags huvudkundnummer. ',
        info3:
          'Ditt gamla API-kundnummer kommer fortfarande att fungera under en övergångsperiod. Vi rekommenderar dock att du ',
        link: 'ändrar API-kundnumret som används för förfrågningar om nämnda tjänster',
        text: ' så snart som möjligt för att undvika problem senare.',
      },
      invoices: {
        label: 'Invoice API',
        info1:
          'Fakturor ska tas ut med huvudkundnummer. Ditt API-huvudkundnummer är identiskt med ditt företags huvudkundnummer. ',
        info2:
          'Ditt gamla API-kundnummer kommer fortfarande att fungera under en övergångsperiod. Vi rekommenderar dock att du ',
        link: 'ändrar API-kundnumret som används för att ta ut fakturor',
        text: ' så snart som möjligt för att undvika problem senare.',
      },
      demosg: {
        label: 'Demo Shipping Guide',
        info: 'Efter bytet till det nya faktureringssystemet kommer du att hitta Home Delivery-tjänster under fliken "Revised services".',
      },
      web: {
        link: 'Översikt över ändringarna i Mybrings webbportal',
      },
    },
    web: {
      title: 'Ändringar i Mybring som följd av nytt faktureringssystem',
      intro:
        'Från och med den 2 maj 2023 byter vi till ett nytt och förbättrat faktureringssystem för våra Home Delivery-tjänster i Norge och Danmark samt regionala budtjänster i Finland. Nedan finns en översikt över de förändringar du kommer att se på olika platser i Mybring.',
      booking: {
        label: 'Beställning',
        new: 'Ny beställning',
        info: 'Beställning av Home Delivery-tjänster till/från Norge och Danmark ska göras med huvudkundnummer. Detta gäller både utgående Home Delivery-tjänster till/från Norge och Danmark under "Sända till en" och Home Delivery-returtjänster till/från Norge och Danmark under "Retur från en". Kundväljaren kommer automatiskt att ändras för att visa huvudkundnummer. För beställning av regionala budtjänster i Finland kommer kundväljaren att vara oförändrad.',
      },
      pickup: {
        new: 'Ny upphämtning',
        info: 'Beställning av upphämtning i Sverige och Danmark ska göras med huvudkundnummer. Kundväljaren ändras automatiskt till att visa huvudkundnummer.',
      },
      orderhistory: {
        label: 'Beställningshistorik',
        info: 'Alla beställningar av Home Delivery-tjänster oavsett land som görs efter bytet till det nya faktureringssystemet kommer att visas med huvudkundnummer. Samma gäller för beställda hämtningar av Home Delivery-sändningar i Norge. Beställningar av regionala budtjänster i Finland kommer att visas med samma kundnummer som tidigare.',
      },
      tracking: {
        label: 'Spårning',
        info: 'Här finns det inga förändringar. Både gammalt kundnummer och huvudkundnummer visas som tidigare.',
      },
      invoices: {
        label: 'Fakturor',
        info1:
          'Fakturor för alla beställningar av Home Delivery-tjänster samt regionala budtjänster i Finland efter bytet till det nya faktureringssystemet kommer att komma under huvudkundnummer.',
        info2:
          'På fakturor under huvudkundnumret kommer du att få mer information om din faktura än det du får i dag, som till exempel:',
        info3: {
          type: 'Fakturatyp',
          source: 'Fakturakälla',
          amount: 'Fakturabelopp',
          currency: 'Valuta',
          text1: 'Vilken ursprunglig faktura en kreditnota är knuten til',
          text2: 'Om fakturan är betald eller inte',
        },
        info4:
          'Du kommer att se några kolumner i kolumnväljaren för Specificerad fakturarapport (dvs fakturaspecifikationen) som inte kommer att ha något innehåll. Anledningen till denna utökning är att Specificerad fakturarapport blir en gemensam rapport för alla kunder, och vissa kolumner är bara giltiga för vissa tjänsteområden.',
        info5:
          'Det är möjligt att skapa en prenumeration på Specificerad fakturarapport. Prenumerationen skapas på huvudkundnummer.',
      },
      customerService: {
        label: 'Kundeservice',
        info: 'Här är det inga ändringar. Du anmäler saker på huvudkundnumret som tidigare.',
      },
      api: {
        label: 'API-ändringar',
        info: 'Du måste ändra till ditt nya API-huvudkundnummer vid användning av enskilda Mybring API:er.',
        link: 'Läs mer',
      },
    },
  },
  missingPhone: {
    heading: 'Saknar telefonnummer',
    text: 'Vi har inget telefonnummer registrerat för din användare. Detta krävs för att använda alla funktioner i Mybring (t.ex. få SMS). För att uppdatera ditt telefonnummer, vänligen gå till ',
    link: 'uppdatera profil-sidan.',
  },
  dashboard: {
    select: 'Välj kundnummer',
    title: 'Planerade leveranser',
    yesterday: 'I går',
    today: 'I dag',
    tomorrow: 'I morgon',
    moredays: 'Se fler dagar',
    error: 'Vi har problem med att visa detaljer. Prova igen senare.',
    loading: 'Laddar',
    remaining: 'På väg',
    loaded: 'Lastat på bil',
    attempted: 'Försökt att leverera',
    pickup: 'På utlämningsställe',
    delivered: 'Levererat',
  },
  overview: {
    titleVol: 'Fakturerad volym',
    titleCost: ' och kostnader',
    loading: 'Laddar',
    error: 'Vi har problem med att visa detaljer. Prova igen senare.',
    menu: {
      thisyear: 'Hittils i år',
      thismonth: 'Hittils denna månaden',
      last12month: 'Senaste 12 månaderna',
      last24month: 'Senaste 24 månaderna',
    },
    category: 'Sändningar',
    info: 'Ingen data tillgänglig för denna kunden',
  },
  welcome: {
    skip: 'Hoppa till innehållet',
    login: 'Logga in',
    forexisting: 'För existerande användare',
    register1: 'Registrera nytt företag',
    register2: 'Få ett kundnummer och en användare för inloggning',
    register3: 'Skapa fler användare',
    register4: 'När företaget redan har ett kundnummer',
    features: {
      heading: 'Få en översikt över logistiken med Mybring',
      booking: 'Beställning',
      bookingIntro:
        'Beställ tjänster som ingår i ditt avtal. Skicka till en och en eller till många mottagare samtidigt.',
      tracking: 'Spårning',
      trackingIntro: 'Ger avsändaren utökad information samt tillgång till signatur- och ändringstjänster.',
      reports: 'Rapporter',
      reportsIntro: 'Få en överblick över status, leveranskvalitet, avvikelser, kostnader och klimatavtryck.',
      invoices: 'Fakturor',
      invoicesIntro: 'Få en översikt över dina fakturor med betalningsfrist och -status.',
      supportcases: 'Kundtjänst',
      supportcasesIntro:
        'Se alla registrerade ärenden samt falllogg och senaste status, och skapa nya ärenden direkt i Mybring.',
      warehousing: 'Warehousing',
      warehousingIntro:
        'Ha full kontroll över ditt lager. Få en överblick över dina försäljnings- och inköpsorder och se den senaste statusen.',
      useradmin: 'Administration',
      useradminIntro: 'Som administratör har du full kontroll över företagets kundnummer och Mybring-användare.',
      link: 'Läs mer om Mybring',
    },
    customerservice: {
      label: 'Kundservice',
      no: 'Bring Norge',
      se: 'Bring Sverige',
      dk: 'Bring Danmark',
      fi: 'Bring Finland',
    },
    terms: 'Villkor',
    download: 'Ladda ner',
    policy: 'Privacy policy',
    cookies: 'Cookies',
  },
  agreementConversion: {
    read: 'Read more.',
    here: 'here',
    notAuthorized:
      'The logged-in user is not authorized to access this page. If you have access to the captioned service, but unable to access this page, please contact customer service.',
    infoPage: {
      header: 'Changes in Mybring related to new service names',
      description: {
        para1:
          'Bring is revising the service portfolio. Some of our services have been given new service names, service codes and pricing models. ',
        para2:
          'From a given date, your company will be converted to services with new names (revised services). When at least one customer number attached to your user has been converted, you will book revised services. This is done by using your company’s main customer number. Below is an overview of the various changes you will see in Mybring.',
        para3: 'To read more about the ',
        link: 'revised services',
        text: ' go directly here.',
      },
      api: {
        header: 'API changes',
        text: 'You use the main customer number for revised services. ',
        introduction: 'Changes in API related to conversion',
        bullets: {
          1: 'To request revised services via our APIs you use your new API customer number, which is identical to your company’s main customer number. To read more about revised services in our APIs, click ',
          2: 'To see your API customer numbers, click ',
          3: 'For a limited period, any bookings of old services made with old API customer numbers will automatically be mapped to the ',
          link: 'corresponding ',
          text: 'revised service. ',
          4: 'If you request old services with your new API-customer number, or revised services with your old API customer number, the request will fail. ',
        },
      },
      booking: {
        header: 'Booking',
        order: 'New Order',
        text: 'You start booking revised services when one customer number attached to your user has been converted. ',
        heading: {
          text1:
            'To check which services you can book with old customer numbers and which services you can book with main customer number, click ',
          text2:
            'For services with new service names the API-request is unchanged, except for the way to request value added services. For old services both old and new way of requesting can be used. However, it is recommended to use the new way for all services as the old way of requesting value added services will be phased out. ',
        },
        table: {
          row: {
            BPAKKE: 'Business Parcel',
            EKSPRESS09: 'Business Parcel Express Overnight',
            CARGO: 'Groupage and Partload*',
            SERVICEPAKKE: 'Climate-neutral Service Parcel',
            PA_DOREN: 'Home Delivery Parcel',
            BPAKKE_RETURSERVICE: 'Business Package Return Service',
            EKSPRESS09_RETURSERVICE: 'Business Parcel Express Overnight Return Service',
            SERVICEPAKKE_RETURSERVICE: 'Service Parcel Return Service',
            5000: 'Business Parcel',
            4850: 'Express next day',
            5100: 'Business groupage',
            5300: 'Business partload',
            5800: 'Pick-up parcel',
            5600: 'Home delivery parcel',
            9000: 'Return business parcel',
            9600: 'Return express',
            9100: 'Return business groupage',
            9300: 'Return from pick-up point',
          },
        },
        text1:
          'After being converted, you will be redirected back to the booking home page if you try to access old services by using bookmarks etc.',
        text2:
          "For revised services, eAdvising is no longer a separate value added service: it's now included in the service and it's base price. Letter notification can be ordered at an additional cost (possible when booking single outbound shipments).",
        flow3: 'Mailbox Packet',
        oldImage: 'Example - Mailbox Packet customer selector before any conversion:',
        newImage:
          'Example - Mailbox Packet customer selector after conversion. Bring Company1 AS and Bring Company3 are converted/main customer numbers. The others have not been converted yet:',
      },
      pickup: {
        header: 'New Pickup',
        text: 'For pickups in Norway, the customer selector will change when you have at least one converted customer number attached to your user. ',
        text1:
          'The customer selector for pickups in Norway will change when you have at least one converted customer number attached to your user. From that point of time, also the customer selector will show at least that one main customer number for invoicing. For parcel pickups in Sweden and Denmark, there will be no changes to the customer selector - i.e. it will continue to show only old customer numbers.',
        text2:
          "Not all organizations are converted simultaneously. If you have multiple organizations' customer numbers attached to your user, you might see both old and main customer numbers in the selector.",
        oldImage: 'Example – Pickup customer selector before any conversion:',
        newImage:
          'Example – Pickup customer selector after conversion. Bring Company1 AS and Bring Company3 AS are converted/main customer numbers. The others have not been converted yet:',
      },
      orderHistory: {
        header: 'Order history',
        text: 'All bookings are reflected in order history. For old service bookings, you will see the old customer number being displayed. For revised service bookings, you will see the main customer number being displayed.',
      },
      addressbook: {
        header: 'Address book',
        text: 'When a customer number attached to your user has been converted, all contacts saved on the old customer number will be migrated to the main customer number. ',
        list: {
          text1:
            'When a customer number attached to your user has been converted, all contacts saved on the old customer number will be migrated to the main customer number. Similarly, all contacts saved on old customer numbers that are not migrated yet will remain on the old customer number. ',
          text2:
            "Not all organizations are converted simultaneously. If you have multiple organizations' customer numbers attached to your user, you might see both old and main customer numbers in the selector.",
          text3: 'New contacts can be added to both old and main customer numbers.',
        },
        image: {
          oldText: 'Example - Address book customer selector before any conversion: ',
          newText:
            'Example - Address book customer selector after conversion. Bring Company1 AS (1000-12345678) and Bring Company1 AS (00011111111) are now converted and merged under the main customer number Bring Company1 AS (11111111). The others have not been converted yet: ',
        },
      },
      shoppingCart: {
        header: 'Shopping cart',
        text: 'Any items in your shopping cart involving old services will automatically be deleted when one of the customer numbers attached to your user has been converted. ',
        oldImage: 'Example - Shopping cart before any conversion (including an old service):',
        newImage: 'Example - Shopping cart after conversion (the old service has been deleted):',
        text1:
          'When at least one customer number attached to your user has been converted, you can only book revised services. If you had any items in your shopping cart involving old services, they have been deleted after the conversion. Your shopping cart will display a message informing about this.',
        text2:
          'This change only applies to the group of services that are impacted by the conversion. If you had any items in your shopping cart involving other services, they still remain in the shopping cart after the conversion.',
      },
      favourites: {
        header: 'Favourites',
        text: 'Any items among your favourites involving old services will automatically be disabled for booking when one of the customer numbers attached to your user has been converted. ',
        oldImage: 'Example - Favourites before any conversion (including an old sevice):',
        newImage: 'Example - Favourites after conversion (the old service has been disabled for booking):',
        text1:
          'When at least one customer number attached to your user has been converted, you can only book revised services. If you have any favourites involving old services, they have been disabled for booking after the conversion. Your favourites page will display a message informing about this.',
        text2:
          'This change only applies to the group of services that are impacted by the conversion. If you have any items among your favourites involving services that are not impacted, they still remain bookable from the favourites page after the conversion.',
        text3:
          'To save the corresponding revised service as a favourite, click "Save as favourite" on the confirmation page next time you book this service.',
      },
      returnAdmin: {
        header: 'Return admin',
        text: 'When a customer number has been converted, any return agreements for old services will automatically be migrated to return agreements for the corresponding revised services. The return agreement for the revised service will be using the main customer number. If you create a new return agreement for a revised service on a converted customer number, you must use the main customer number when searching. For new return agreements on customer numbers that are not converted, the old customer number is used. The same goes for new return agreements for services outside scope of conversion - even though the customer is converted.',
        text1: 'Old return services impacted by the conversion and the new service name/code they are mapped to:',
        table: {
          heading1: 'Old service name',
          heading2: 'Old service code',
          heading3: 'New service name',
          heading4: 'New service code',
          row1: {
            oldService: {
              text: 'Business Package Return Service',
              code: 'BPAKKE_DOR-DOR_RETURSERVICE (1225)',
            },
            newService: {
              text: 'Return business parcel',
              code: '9000',
            },
          },
          row2: {
            oldService: {
              text: 'Business Parcel Express Overnight Return Service',
              code: 'EKSPRESS09_RETURSERVICE (1224)',
            },
            newService: {
              text: 'Return express',
              code: '9600',
            },
          },
          row3: {
            oldService: {
              text: 'Service Parcel Return Service',
              code: 'SERVICEPAKKE_RETURSERVICE (1207)',
            },
            newService: {
              text: 'Return from pick-up point',
              code: '9300',
            },
          },
        },
        text2: '',
      },
      adminBulksplit: {
        header: 'Bulk admin',
        text: 'Bulk agreements only apply to services that are not covered by the conversion, and there are no changes to existing bulk agreements.',
        text1:
          'Bulk agreements only apply to services that are not covered by the conversion, and there are no changes to existing bulk agreements. It’s possible to search for customers in bulk admin by using old customer numbers or main customer numbers, depending on whether the customer is converted yet or not. When searching for main customer numbers, relevant old customer numbers will show, and the user must choose which old customer number to create the bulk agreement on.',
        imageText: 'Example - Search in bulk admin for converted customer: ',
      },
      calculator: {
        header: 'Calculator',
        text: 'You can calculate prices and leadtimes for revised services when one customer number attached to your user has been converted. ',
        text1:
          'The customer selector in calculator will change when you have at least one converted customer number attached to your user. From that point of time, you can use the main customer number to request for prices and leadtimes for the revised services Business groupage and Business partload.',
        text2:
          "Not all organizations are converted simultaneously. If you have multiple organizations' customer numbers attached to your user, you might see both old and main customer numbers in the selector. In that case, you can still calculate prices and leadtimes for the old service Groupage and Partload by using one of the old customer numbers in the dropdown.",
        text3:
          'Since the old service Groupage and Partload is split into two revised services, Business groupage and Business partload, you must now select which of the revised services you are requesting for when using the calculator with your main customer number.',
        oldImage: 'Example - Calculator before any conversion (old customer number/old service):',
        newImage: 'Example - Calculator after conversion (main customer number/revised services):',
      },
      tracking: {
        header: 'Tracking',
        text: 'There will be no changes in tracking. We display both the old and the main customer number, as before.',
      },
      reports: {
        header: 'Reports',
        text: 'In the customer selector we hide the old customer numbers after conversion, except from Bring Parcels AB. ',
        subheading: 'Reports & Subscriptions',
        text1:
          'Reports are requested using main customer numbers, and will include all old customer numbers for the selected service type. If you previously had 3 customer numbers for groupage that are all combined within the same main customer number, all of these will be included in the report.',
        text2: 'The columns in the reports are kept, and both old and new services are included in the same report.',
        heading: 'Reports Subscriptions',
        text3:
          'We move subscriptions from old customer numbers to main customer numbers, with the same setup as before. The subscription owner will get an email when that happens. If you used to have subscriptions for multiple customer numbers that are combined within the same main customer number, you might end up with duplicate subscriptions. In that case, you can delete these in Reports-Subscriptions.',
        oldImage: 'Example of old and new customer selector',
        newImage: 'Subscription page where you can edit your subscriptions',
      },
      dashboard: {
        header: 'Dashboard',
        text: 'The old customer numbers will not be available, but the service types will be. If you previously had 3 customer numbers for groupage that now are combined within the same main customer number, these will be combined in the reporting.',
      },
      invoices: {
        header: 'Invoices',
        text: 'When your customers are converted, the old customer numbers are removed from the customer selector. Invoices for old customer numbers are available when selecting main customer number. Invoices will still be available in one year.',
      },
      customerservice: {
        header: 'Customer Service',
        text: 'No changes. You still create cases using main customer numbers, as before.',
      },
      orderManagement: {
        header: 'Order Management',
        text: 'For all APIs, we will use new customer number instead of old customer number. Also, all suppliers will be ported to new customer number. For more details',
        text1:
          "Users having customers for which agreement has been migrated to new customer number, will use new customer number for all API's and UI.",
        heading1: {
          label: 'API Changes',
          subheading1: {
            label: 'IsCustomerValidForOrderManagement',
            text: 'For this api new customer number will be used, because for a customer for which agreement has been migrated to new customer all pickup order will be cretaed on new customer number.',
          },
          subheading2: {
            label: 'ListOrderInformation',
            text: 'As order will be created using new customer number, so to list order info new customer number will be used.',
          },
          subheading3: {
            label: 'ListOrderItems',
            text: 'As order will be created using new customer number, so to list order items new customer number will be used.',
          },
          subheading4: {
            label: 'SenderStatusForOrder',
            text: 'User will have to use new customer number to find sender status.',
          },
          subheading5: {
            label: 'ListPackagingListForOrder',
            text: 'Users will have to use new customer number to get packaging list of order as order for agreement converted customers will be created using new customer number.',
          },
          subheading6: {
            label: 'Report API',
            text: 'For all the order for which booking has been done using new customer number, will have to use new customer number to generate all types of reports.',
          },
        },
        heading2: {
          label: 'Mybring UI Changes',
          text1:
            'Users will have to search purchase order using new customer number for converted customer (dropdown will show new customer number for customer having agreement with new customer number, and for customers with old agreement, old customer number will be displayed.)',
          text2:
            'User will have to create supplier using new customer number for any customer that is new to Purchase order',
          text3:
            'User having suppliers on old customer number will automatically changed to supplier with new customer number',
          text4:
            'All suppliers that has to be used in purchase order by a user, should be added to that user using super user profile.',
        },
      },
      shippingguide: {
        header: 'Shipping guide',
        text1: 'You need to upgrade to ',
        link: 'Shipping Guide 2.0 ',
        text2:
          'to be able to request services with new service names after conversion. Shipping Guide 2.0 supports both old services and services with new names, so it is recommended that you upgrade as quickly as possible to avoid that your integration stops working.',
      },
      newPickup: {
        header: 'New pickup',
        text: 'After your company’s conversion date, you start requesting pickups in Norway with your new API customer number. Your old API customer number will still work for a transition period. However, it is recommended to use your new API customer number as soon as you are converted. For pickups outside Norway there are no changes, and you continue to use your old API customer number.',
      },
      customerServiceMapping: {
        header: 'Mapping between customer number and available services',
        text: 'Which customer numbers attached to your user that can be used to book which services.',
        superuser:
          "You are logged in as a superuser. As a superuser you don't have any customer numbers attached to your user, hence customer/service mapping in not available for you.",
        customer: {
          name: 'Name',
          customerNo: 'Customer Number',
          code: 'Country Code',
          products: 'Products',
        },
      },
      promotions: {
        text1:
          'When you do a coverage search NO -> NO with at least one converted customer number attached to your user, you will see revised services instead of old services. When booking revised services, you will be using your main customer number - i.e the customer selector will show your main customer number(s) for invoicing. The aforementioned changes apply to single outbound/return as well as multiple outbound/return.',
        text2:
          'There are no changes to services that apply to other country combinations, and you will continue using your old customer number for these bookings. To check which services you can book with which customer number, click ',
        text3: 'Old services impacted by the conversion, and which new service name/code they are mapped to: ',
        table: {
          heading1: 'Old service name',
          heading2: 'Old service code',
          heading3: 'New service name',
          heading4: 'New service code',
        },
      },
      cargo:
        '* There is a one-to-one mapping between the old and revised services (and thereby their respective booking flows) - except for the old service Groupage and Partload. This service had one common booking flow, but is split into two revised services/booking flows: Business Groupage and Business Partload. A separate booking flow has also been created for the revised service Return business groupage (New order -> Single return). The old corresponding service was booked via the booking flow for Groupage and Partload by selecting "Return" on the shipping details page. ',
      coverageSearch: {
        oldImage: 'Outgoing coverage search NO -> NO before conversion (old services): ',
        newImage: 'Outgoing coverage search NO -> NO after conversion (revised services): ',
      },
      orderToMailbox: {
        text1:
          'The customer selector for Mailbox Packet will change when you have at least one converted customer number attached to your user. From that point of time, the customer selector will show at least that one main customer number for invoicing. ',
        text2:
          "Not all organizations are converted simultaneously. If you have multiple organizations' customer numbers attached to your user, you might see both old and main customer numbers in the selector.",
      },
    },
  },
  operationalMessages: {
    backtofrontpage: 'Tillbaka till förstasidan',
    select: 'Välj land',
    title: 'Driftsmeddelanden',
    seemore: 'Fler driftsmeddelanden',
    loading: 'Laddar',
    country: {
      no: 'Norge',
      se: 'Sverige',
      dk: 'Danmark',
    },
    error: 'Vi har problem med att visa driftsmeddelanden. Försök igen senare.',
    none: 'Inga meddelanden att visa.',
  },
  notcollectedatpup: {
    label: 'Inte hämtats vid utlämningsställe',
    onetotwodays: '1-2 dagar kvar',
    threetofourdays: '3-4 dagar kvar',
    error: 'Vi har problem med att visa detaljer. Prova igen senare.',
  },
  taskCenter: {
    messages: 'Messages',
    useradmin:'Administration',
    cases: {
      caseNumber: 'Ärende: ',
      status: {
        NEW: 'Ny',
        IN_PROGRESS: 'Behandlas',
        AWAITING_CUSTOMER: 'Väntar på kund',
        CLOSED: 'Avslutat',
      },
    },
  },
  searchable_select: {
    customerEmptyText: 'Inga kunder matchar din sökning...',
    customerPlaceholderText: 'Sök och välj en av dina kunder...',
    listboxLabelCustomer: 'Lista med valbara kunder',
  },
  bankInfo: {
    header: 'Bankbyte | Detta måste du göra',
    p1: 'Posten Bring-koncernen byter koncernbank från Nordea till SEB under våren 2024. När bytet är genomfört kommer fakturorna att innehålla ny betalningsinformation.',
    p2: 'Läs mer här: ',
    ctaLabel: 'Byte av koncernbank för Posten Bring AS',
    ctaLink: 'https://www.bring.se/kundservice/kundservice-foretag/byte-koncernbank'
  },
  checkITContactInfo: {
    header: 'Gratis rådgivning om checkout för webbutiker',
    text: 'Vill du ha hjälp med att optimera din checkout? Våra checkout-specialister är redo att hjälpa dig! Kontakta oss på <1>checkoutse@bring.com</1>',
    emailLink: 'mailto:checkoutse@bring.com',
  },
};

export default sv;